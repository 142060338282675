import React from 'react'

export const PlugCcsDcSIcon = ({
	width = 24,
	height = 24,
	fill = 'currentColor',
	viewBox = '0 0 24 24',
	className = '',
}) => {
	return (
		<svg width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" className={className}>
			<path
				fill={fill}
				d="M7.74 3a.5.5 0 0 0-.34.12 6.83 6.83 0 0 0 5.38 11.84 6.82 6.82 0 0 0 3.8-11.84.5.5 0 0 0-.33-.12H7.74Zm-1-.62a1.5 1.5 0 0 1 1-.38h8.51c.36 0 .72.12 1 .38A7.82 7.82 0 0 1 15.83 15h1.68a2.5 2.5 0 0 1 2.5 2.5v2a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 4 19.5v-2A2.5 2.5 0 0 1 6.5 15h1.66A7.83 7.83 0 0 1 6.73 2.38ZM10 5v2H9V5h1Zm5 0v2h-1V5h1ZM7 9a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-6 3a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm4 0a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm-6.5 4c-.83 0-1.5.67-1.5 1.5v2c0 .83.67 1.5 1.5 1.5h11c.83 0 1.5-.67 1.5-1.5v-2c0-.83-.67-1.5-1.5-1.5h-11Zm2 2a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm8.5-.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm-1.5.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
			/>
		</svg>
	)
}
