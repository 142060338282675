import React, { useEffect, useState } from 'react'
import { Button, FloatButton } from 'antd'
import { BugOutlined } from '@ant-design/icons'

import styles from './style.module.scss'

export const ReportButton = () => {
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 768) {
				setIsMobile(true)
			} else {
				setIsMobile(false)
			}
		}

		handleResize()

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const handleReport = () => {
		window.location.href = 'https://forms.gle/J5oPrzLQYrPbE9vr6'
	}

	return (
		<>
			{isMobile ? (
				<FloatButton onClick={handleReport} icon={<BugOutlined />} />
			) : (
				<Button className={styles.stickyReportButton} onClick={handleReport}>
					Report Problem
				</Button>
			)}
		</>
	)
}
