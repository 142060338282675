import React from "react";


export const ArrowInDivider = ({ style, className }) => {
    return <svg
        version="1.1"
        id="svg1"
        width="85px"
        height="78px"
        viewBox="0 0 85 78"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
        className={className}
    >
        <defs
            id="defs1" />
        <g
            id="g1"
            transform="translate(-910.44934,-1182.76)">
            <g
                id="group-R5">
                <path
                    id="path102"
                    d="m 7465,15604.3 v -590.6 l -636.63,295.3 z"
                    style={{ fill: '#5ac134' }}
                    transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
            </g>
        </g>
    </svg>;
};
