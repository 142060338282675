// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_extendContainer__3hxBo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_extendContainer__3hxBo .style_dropDownRow__98Bpj {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  flex-direction: column;
}
.style_extendContainer__3hxBo .style_diagramRow__aMTTL {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .style_extendContainer__3hxBo .style_diagramRow__aMTTL {
    flex-direction: column;
  }
}
.style_extendContainer__3hxBo .style_buttonStatistic__Uqoi0 {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .style_extendContainer__3hxBo .style_buttonStatistic__Uqoi0 {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .style_extendContainer__3hxBo .style_buttonStatistic__Uqoi0 h2 {
    font-size: 18px;
    text-align: center;
  }
}
.style_extendContainer__3hxBo .style_middleDiagram__UWz8X {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.style_extendContainer__3hxBo .style_middleDiagram__UWz8X .style_middleRow__bzSLn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  height: 200px;
}
@media screen and (max-width: 768px) {
  .style_extendContainer__3hxBo .style_middleDiagram__UWz8X .style_middleRow__bzSLn {
    flex-direction: column;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .style_extendContainer__3hxBo .style_middleDiagram__UWz8X h2 {
    text-align: center;
  }
}
.style_extendContainer__3hxBo .style_selectContainer__5y9Z4 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/bevBoardTab/extend/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,sBAAA;AAEJ;AAAE;EACE,aAAA;EACA,WAAA;EACA,SAAA;EACA,uBAAA;AAEJ;AADI;EALF;IAMI,sBAAA;EAIJ;AACF;AAFE;EACE,aAAA;EACA,SAAA;AAIJ;AAHI;EAHF;IAII,sBAAA;EAMJ;AACF;AAJM;EADF;IAEI,eAAA;IACA,kBAAA;EAON;AACF;AAJE;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AAMJ;AALI;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAON;AANM;EANF;IAOI,sBAAA;IACA,YAAA;EASN;AACF;AANM;EADF;IAEI,kBAAA;EASN;AACF;AAHE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAKJ","sourcesContent":[".extendContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  .dropDownRow {\n    display: flex;\n    gap: 20px;\n    margin-bottom: 40px;\n    flex-direction: column;\n  }\n  .diagramRow {\n    display: flex;\n    width: 100%;\n    gap: 20px;\n    justify-content: center;\n    @media screen and (max-width: 768px) {\n      flex-direction: column;\n    }\n  }\n  .buttonStatistic {\n    display: flex;\n    gap: 20px;\n    @media screen and (max-width: 768px) {\n      flex-direction: column;\n    }\n    h2 {\n      @media screen and (max-width: 768px) {\n        font-size: 18px;\n        text-align: center;\n      }\n    }\n  }\n  .middleDiagram {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    .middleRow {\n      display: flex;\n      gap: 20px;\n      align-items: center;\n      justify-content: center;\n      height: 200px;\n      @media screen and (max-width: 768px) {\n        flex-direction: column;\n        height: auto;\n      }\n    }\n    h2 {\n      @media screen and (max-width: 768px) {\n        text-align: center;\n      }\n    }\n  }\n  .selectType {\n    // width: 100px;\n  }\n  .selectContainer {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"extendContainer": `style_extendContainer__3hxBo`,
	"dropDownRow": `style_dropDownRow__98Bpj`,
	"diagramRow": `style_diagramRow__aMTTL`,
	"buttonStatistic": `style_buttonStatistic__Uqoi0`,
	"middleDiagram": `style_middleDiagram__UWz8X`,
	"middleRow": `style_middleRow__bzSLn`,
	"selectContainer": `style_selectContainer__5y9Z4`
};
export default ___CSS_LOADER_EXPORT___;
