import React from "react";
import styles from "./style.module.scss";

export const ChargeIconActive = ({ isCharging, isPlug, isDc }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles.activeChargeIcon}
    viewBox="0 0 690 270"
  >
    <path
      d="M9382.47 20269.2h-776.617v-40h776.617Zm141.35-1209.2v1868.8c-.02 91.4-74.15 165.6-165.62 165.6h-728.08c-91.48 0-165.61-74.2-165.62-165.6V19060h40v1868.8c.01 34.7 14.03 66 36.79 88.8 22.8 22.7 54.07 36.8 88.83 36.8h728.08c34.75 0 66.03-14.1 88.82-36.8 22.77-22.8 36.79-54.1 36.8-88.8V19060Zm101.34 44.3H8363.15v-40h1262.01z"
      style={{
        fill: `${isPlug ? "#FFFFFF" : "#6f7375"}`,
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 -591.155 2812.587)"
    />
    <path
      d="m9088.09 20090.8-254.32-411.6h284.78l-250.79-324.1 31.64-24.5 300.69 388.6h-294.58l216.61 350.5-34.03 21.1"
      style={{
        fill: `${isPlug ? "#FFFFFF" : "#6f7375"}`,
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 -591.155 2812.587)"
    />
    <path
      d="M9046.24 19348.9h-165.9v165.9h-40v-205.9h205.9v40"
      style={{
        fill: `${isPlug ? "#FFFFFF" : "#6f7375"}`,
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 -591.155 2812.587)"
    />
    <path
      d="M4433.66 19703.2v-25h172.69v50h-172.69v-50 25-25h172.69v50h-172.69v-50zm0 291.3v-25h172.69v50h-172.69v-50 25-25h172.69v50h-172.69v-50zm172.69 145.6v-25h291.23c73.6 0 140-29.8 188.26-78 48.21-48.2 77.96-114.7 77.97-188.2 0-11-.66-22.2-2.03-33.5-8.18-67.2-42.42-125.1-92.13-166.6-49.7-41.5-114.68-66.2-183.12-66.2h-255.18v557.5h-25v-25 25h-25v-607.5h305.18c80.46 0 156.53 28.8 215.16 77.8 58.61 48.9 99.95 118.3 109.72 199 1.62 13.3 2.4 26.4 2.4 39.5-.01 174.6-141.57 316.2-316.23 316.2h-316.23v-25h25"
      style={{
        fill: `${
          isCharging && !isDc ? "#5ac134" : isDc ? "#f37037" : "#6f7375"
        }`,
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.13333 0 0 -.13333 -591.155 2812.587)"
    />

    {isCharging ? (
      <path
        className={isDc ? styles.pathEnergyDc : styles.pathEnergy}
        d="M8425,20265 H7479.73 C7358.68,20265 7259.59,20166.9 7259.57,19945.8 H7256.51 C7256.5,19899 7237.61,19856.8 7206.96,19826.1 C7176.27,19795.5 7134.14,19776.6 7087.35,19776.6 H5211.99"
        fill="none"
        transform="matrix(.13333 0 0 -.13333 -591.155 2812.587)"
      />
    ) : (
      <path
        d="M8425 20265h-945.27c-121.05 0-219.14-98.1-219.16-219.2h25l-17.01 18.4-3.06-2.9-7.99-7.4v-10.9c-.01-46.8-18.89-88.9-49.54-119.6-30.69-30.6-72.82-49.5-119.61-49.5H5213.09v-50h1875.27c121.04 0 219.14 98.1 219.15 219.1h-25l17.02-18.3 3.05 2.8 7.99 7.4v10.9c.01 46.8 18.89 89 49.54 119.7 30.69 30.6 72.82 49.5 119.62 49.5H8425v50"
        style={{
          fill: "#6f7375",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        transform="matrix(.13333 0 0 -.13333 -591.155 2812.587)"
      />
    )}
  </svg>
);
