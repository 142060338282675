// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
a,
p,
label,
button {
	font-family: Poppins, sans-serif;
}

h1 {
	@media screen and (max-width: 767px) {
		font-size: 21px;
		line-height: 33px;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.ant-card-head-title {
	font-size: 18px;
	color: #001529;
}
.ant-modal-title {
	font-size: 15px;
	color: #001529;
}
.ant-tabs-nav {
	margin: 0 !important;
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,4CAA4C;CAC5C,cAAc;CACd,oBAAoB;AACrB;AACA;;;;;;;;;;;;CAYC,gCAAgC;AACjC;;AAEA;CACC;EACC,eAAe;EACf,iBAAiB;CAClB;AACD;;AAEA;CACC;EACC,4CAA4C;CAC7C;AACD;;AAEA;CACC,yBAAyB;CACzB,iBAAiB;CACjB,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;CACvB,6BAA6B;CAC7B,YAAY;AACb;;AAEA;CACC,cAAc;AACf;AACA;CACC,eAAe;CACf,cAAc;AACf;AACA;CACC,eAAe;CACf,cAAc;AACf;AACA;CACC,oBAAoB;AACrB;AACA;CACC;EACC,uBAAuB;CACxB;CACA;EACC,yBAAyB;CAC1B;AACD","sourcesContent":[".App {\n\ttext-align: center;\n}\n\n.App-logo {\n\tanimation: App-logo-spin infinite 20s linear;\n\theight: 40vmin;\n\tpointer-events: none;\n}\nbody,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6,\nspan,\na,\np,\nlabel,\nbutton {\n\tfont-family: Poppins, sans-serif;\n}\n\nh1 {\n\t@media screen and (max-width: 767px) {\n\t\tfont-size: 21px;\n\t\tline-height: 33px;\n\t}\n}\n\n@media (prefers-reduced-motion: no-preference) {\n\t.App-logo {\n\t\tanimation: App-logo-spin infinite 20s linear;\n\t}\n}\n\n.App-header {\n\tbackground-color: #282c34;\n\tmin-height: 100vh;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: calc(10px + 2vmin);\n\tcolor: white;\n}\n\n.App-link {\n\tcolor: #61dafb;\n}\n.ant-card-head-title {\n\tfont-size: 18px;\n\tcolor: #001529;\n}\n.ant-modal-title {\n\tfont-size: 15px;\n\tcolor: #001529;\n}\n.ant-tabs-nav {\n\tmargin: 0 !important;\n}\n@keyframes App-logo-spin {\n\tfrom {\n\t\ttransform: rotate(0deg);\n\t}\n\tto {\n\t\ttransform: rotate(360deg);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
