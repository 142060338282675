import React from "react";
import styles from "./style.module.scss";


export const ChargerIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="280px" height="270px" viewBox="0 0 280 270" className={styles.chargeInActive}>
        <path
            d="M22482.5 20219.2h-776.699v-40h776.699zm141.3-1209.2v1868.8c0 91.4-74.1 165.6-165.6 165.6h-728.1c-91.5 0-165.6-74.2-165.6-165.6V19010h40v1868.8c0 34.7 14 66 36.8 88.8 22.8 22.7 54.1 36.8 88.8 36.8h728.1c34.7 0 66-14.1 88.8-36.8 22.8-22.8 36.8-54.1 36.8-88.8V19010Zm101.4 44.3h-1262v-40h1262z"
            style={{
                fill: "#6f7375",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.13333 0 0 -.13333 -2745.827 2805.92)" />
        <path
            d="m22188.1 20040.8-254.3-411.6h284.7l-250.7-324.1 31.6-24.5 300.7 388.6h-294.6l216.6 350.5-34 21.1"
            style={{
                fill: "#6f7375",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.13333 0 0 -.13333 -2745.827 2805.92)" />

        <path
            d="M22146.2 19298.9h-165.9v165.9h-40v-205.9h205.9v40M20885 20126.4v-655.3c0-118.3 95.8-214.1 214.1-214.1s214.2 95.8 214.2 214.1v534c0 48.1 19.4 91.5 51 123.1 31.6 31.5 75 51 123.1 51h72.8v40h-72.8c-118.3 0-214.1-95.9-214.1-214.1v-534c0-48.1-19.5-91.5-51-123.1-31.6-31.6-75-51-123.2-51-48.1 0-91.6 19.4-123.1 51-31.6 31.6-51 75-51 123.1v655.3h-40"
            style={{
                fill: "#6f7375",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.13333 0 0 -.13333 -2745.827 2805.92)" /><path
            d="M20759.3 20975.8h-20v-242.7h40v242.7h-40 20-20v-242.7h40v242.7h-40zm291.3 0h-20v-242.7h40v242.7h-40 20-20v-242.7h40v242.7h-40zm145.6-242.7h-20v-291.2c0-75-30.3-142.6-79.5-191.8-49.1-49.1-116.8-79.4-191.7-79.4-11.2 0-22.6.6-34.1 2-68.5 8.4-127.6 43.3-169.9 93.9-42.2 50.6-67.3 116.7-67.3 186.3v260.2h562.5v20h-20 20v20h-602.5v-300.2c0-79.2 28.4-154.2 76.6-211.9 48.2-57.7 116.5-98.4 195.8-108 13.1-1.6 26-2.3 38.9-2.3 171.9 0 311.2 139.3 311.2 311.2v311.2h-20v-20"
            style={{
                fill: "#6f7375",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.13333 0 0 -.13333 -2745.827 2805.92)" />

    </svg>
);
