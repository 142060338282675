import { Button, Form, Input, InputNumber, Progress, Slider, Card, Divider, message } from 'antd'
import { PercentageOutlined } from '@ant-design/icons'
import styles from './style.module.scss'
import React, { useState, useEffect } from 'react'
import { virtualGenerate, getWeekendTripProfile, getVehicleProfile, getUserProfile } from '@services/api'

function VirtualFormTab() {
	const [amount, setAmount] = useState(1)
	const [prefix, setPrefix] = useState('')
	const [profileValues, setProfileValues] = useState({
		vbev_large: 0,
		vbev_medium: 0,
		vbev_small: 0,
	})
	const [userTypes, setUserTypes] = useState({
		commuter_far: 0,
		commuter_near: 0,
		housewife: 0,
		pensioner: 0,
	})
	const [profileProgress, setProfileProgress] = useState(100)
	const [userShapeProgress, setUserShapeProgress] = useState(100)
	const [profiles, setProfiles] = useState({})
	const [form] = Form.useForm()
	const [messageApi, contextHolder] = message.useMessage()

	const info = (message) => {
		messageApi.info(message)
	}

	const isProgressComplete = userShapeProgress === 100 && profileProgress === 100

	const getProfiles = async () => {
		const vehicleProfile = await getVehicleProfile()
		const userProfile = await getUserProfile()
		const weekendTripProfile = await getWeekendTripProfile()
		setProfiles({
			vehicleProfile,
			userProfile,
			weekendTripProfile,
		})
	}
	const clear = () => {
		setAmount(1)
		form.resetFields()
		setProfileValues({
			vbev_large: 0,
			vbev_medium: 0,
			vbev_small: 0,
		})
		setUserTypes({
			commuter_far: 0,
			commuter_near: 0,
			housewife: 0,
			pensioner: 0,
		})
	}

	const onFinish = async () => {
		const userProfileMap = Object.fromEntries(
			profiles.userProfile.map((profile) => [profile.name.toLowerCase(), profile])
		)
		const vehicleProfileMap = Object.fromEntries(
			profiles.vehicleProfile.map((profile) => [profile.name.toLowerCase(), profile])
		)
		const weekendTripProfileMap = Object.fromEntries(
			profiles.weekendTripProfile.map((profile) => [profile.name.toLowerCase(), profile])
		)

		const obj = {
			enabled: true,
			vinPrefix: prefix,
			vehiclesAmount: amount,
			allocatedUserGenerationProfiles: Object.entries(userTypes).map(([key, value]) => {
				const profile = userProfileMap[key]
				if (!profile) {
					throw new Error(`User profile for key ${key} is undefined`)
				}
				return {
					userGenerationProfileId: profile.id,
					allocation: (value / 100).toFixed(2),
				}
			}),
			allocatedVehicleGenerationProfiles: Object.entries(profileValues).map(([key, value]) => {
				const profile = vehicleProfileMap[key]
				if (!profile) {
					throw new Error(`Vehicle profile for key ${key} is undefined`)
				}
				return {
					vehicleGenerationProfileId: profile.id,
					allocation: (value / 100).toFixed(2),
				}
			}),
			allocatedWeekendTripGenerationProfiles: Object.values(weekendTripProfileMap).map((profile) => ({
				weekendTripGenerationProfileId: profile.id,
				allocation: 0.25,
			})),
		}
		await virtualGenerate(obj)
		clear()
	}
	const generateRandomProfileValues = () => {
		const values = generateRandomValues(3)
		setProfileValues({
			vbev_large: values[0],
			vbev_medium: values[1],
			vbev_small: values[2],
		})
	}

	const generateRandomUserTypes = () => {
		const values = generateRandomValues(4)
		setUserTypes({
			commuter_far: values[0],
			commuter_near: values[1],
			housewife: values[2],
			pensioner: values[3],
		})
	}

	const generateRandomValues = (count) => {
		let values = Array(count)
			.fill(0)
			.map(() => Math.random())
		const total = values.reduce((acc, value) => acc + value, 0)
		values = values.map((value) => Math.round((value / total) * 100))

		const finalTotal = values.reduce((acc, value) => acc + value, 0)
		const difference = 100 - finalTotal
		values[0] += difference

		return values
	}
	const onFinishFailed = (errorInfo) => {
		info(`${errorInfo} Failed`)
	}

	const onProfileChange = (newValue, changedSlider) => {
		setProfileValues((prevValues) => {
			const totalWithoutChanged = Object.entries(prevValues)
				.filter(([key]) => key !== changedSlider)
				.reduce((total, [, value]) => total + value, 0)

			const availableForChanged = 100 - totalWithoutChanged
			const newSliderValue = Math.min(newValue, availableForChanged)

			return {
				...prevValues,
				[changedSlider]: newSliderValue,
			}
		})
	}
	const onUserShapeChange = (newValue, changedSlider) => {
		setUserTypes((prevValues) => {
			const totalWithoutChanged = Object.entries(prevValues)
				.filter(([key]) => key !== changedSlider)
				.reduce((total, [, value]) => total + value, 0)

			const availableForChanged = 100 - totalWithoutChanged
			const newSliderValue = Math.min(newValue, availableForChanged)

			return {
				...prevValues,
				[changedSlider]: newSliderValue,
			}
		})
	}
	useEffect(() => {
		getProfiles()
	}, [])
	useEffect(() => {
		const profileTotal = Object.values(profileValues).reduce((sum, value) => sum + value, 0)
		setProfileProgress(profileTotal)
		const userShapeTotal = Object.values(userTypes).reduce((sum, value) => sum + value, 0)
		setUserShapeProgress(userShapeTotal)
	}, [profileValues, userTypes])
	return (
		<div className={styles.virtualForm}>
			{contextHolder}
			<h1>Please, specify input parameters to generate Virtual BEVs</h1>
			<Form
				form={form}
				labelCol={{
					span: 100,
				}}
				wrapperCol={{
					span: 18,
				}}
				style={{
					width: `100%`,
					maxWidth: 600,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Card title="Virtual BEV Amount">
					<Form.Item>
						<InputNumber defaultValue="1" min="1" max="300" onChange={(value) => setAmount(value)} value={amount} />
					</Form.Item>
				</Card>
				<Divider />
				<Card title="Vin Prefix">
					<Form.Item
						name="prefix"
						rules={[
							{ required: true, message: 'Prefix required' },
							{ max: 10, message: 'maximum 10 characters' },
							{ min: 3, message: 'minimum 3 characters' },
						]}
					>
						<Input placeholder="DEV" onChange={(e) => setPrefix(e.target.value)} />
					</Form.Item>
				</Card>
				<Divider />
				<Card title="Profile Share">
					<Button type="primary" size="medium" onClick={generateRandomProfileValues}>
						Random generate
					</Button>
					<div className={styles.profMain}>
						<div className={styles.profLeft}>
							<div className={styles.profCol}>
								<h4>Virtual BEV Large</h4>
								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onProfileChange(value, 'vbev_large')}
										className={styles.sliderStyle}
										value={typeof profileValues.vbev_large === 'number' ? profileValues.vbev_large : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={profileValues.vbev_large}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onProfileChange(value, 'vbev_large')}
									/>

									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
							<div className={styles.profCol}>
								<h4>Virtual BEV Medium</h4>
								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onProfileChange(value, 'vbev_medium')}
										className={styles.sliderStyle}
										value={typeof profileValues.vbev_medium === 'number' ? profileValues.vbev_medium : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={profileValues.vbev_medium}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onProfileChange(value, 'vbev_medium')}
									/>
									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
							<div className={styles.profCol}>
								<h4>Virtual BEV Small</h4>
								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onProfileChange(value, 'vbev_small')}
										className={styles.sliderStyle}
										value={typeof profileValues.vbev_small === 'number' ? profileValues.vbev_small : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={profileValues.vbev_small}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onProfileChange(value, 'vbev_small')}
									/>
									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
						</div>
						<div className={styles.proRight}>
							<Progress type="circle" percent={profileProgress} format={() => `${profileProgress}%`} />
						</div>
					</div>
				</Card>
				<Divider />

				<Card title="User Type Share">
					<Button type="primary" size="medium" onClick={generateRandomUserTypes}>
						Random generate
					</Button>
					<div className={styles.profMain}>
						<div className={styles.profLeft}>
							<div className={styles.profCol}>
								<h4>Commuter Far</h4>
								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onUserShapeChange(value, 'commuter_far')}
										className={styles.sliderStyle}
										value={typeof userTypes.commuter_far === 'number' ? userTypes.commuter_far : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={userTypes.commuter_far}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onUserShapeChange(value, 'commuter_far')}
									/>
									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
							<div className={styles.profCol}>
								<h4>Commuter Near</h4>
								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onUserShapeChange(value, 'commuter_near')}
										className={styles.sliderStyle}
										value={typeof userTypes.commuter_near === 'number' ? userTypes.commuter_near : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={userTypes.commuter_near}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onUserShapeChange(value, 'commuter_near')}
									/>
									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
							<div className={styles.profCol}>
								<h4>Housewife</h4>

								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onUserShapeChange(value, 'housewife')}
										className={styles.sliderStyle}
										value={typeof userTypes.housewife === 'number' ? userTypes.housewife : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={userTypes.housewife}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onUserShapeChange(value, 'housewife')}
									/>
									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
							<div className={styles.profCol}>
								<h4>Pensionist</h4>
								<div className={styles.profProp}>
									<Slider
										min={0}
										max={100}
										onChange={(value) => onUserShapeChange(value, 'pensioner')}
										className={styles.sliderStyle}
										value={typeof userTypes.pensioner === 'number' ? userTypes.pensioner : 0}
									/>
									<InputNumber
										min={0}
										max={100}
										value={userTypes.pensioner}
										style={{ width: 50 }}
										size="large"
										onChange={(value) => onUserShapeChange(value, 'pensioner')}
									/>
									<PercentageOutlined style={{ fontSize: 24, marginLeft: 12 }} />
								</div>
							</div>
						</div>
						<div className={styles.proRight}>
							<Progress type="circle" percent={userShapeProgress} format={() => `${userShapeProgress}%`} />
						</div>
					</div>
				</Card>
				<Divider />

				<div className={styles.buttonContainer}>
					<Button type="primary" htmlType="submit" size="large" disabled={!isProgressComplete}>
						Generate
					</Button>
				</div>
			</Form>
		</div>
	)
}

export default VirtualFormTab
