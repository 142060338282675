import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import * as serviceWorker from './serviceWorker'
import keycloak from 'keycloak'

import './assets/fonts/EuroPlate.ttf'
import './index.css'

keycloak
	.init({ onLoad: process.env.REACT_APP_KEYCLOAK_ONLOAD })
	.then((authenticated) => {
		if (authenticated) {
			ReactDOM.createRoot(document.getElementById('root')).render(
				<React.StrictMode>
					<App />
				</React.StrictMode>
			)
		}

		if (!authenticated) {
			keycloak.login()
		}
	})
	.catch((error) => {
		console.error('Failed to initialize Keycloak: ', error)
	})

serviceWorker.unregister()
