import React from "react";
import styles from "./style.module.scss";


export const BatteryIcon = ({ capacity, isCharging, isPlug }) => {
    return (<svg
        version="1.1"
        id="svg1"
        width="240px"
        height="470px"
        viewBox="0 0 240 470"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.batteryIcon}
    >
        <defs
            id="defs1" />
        <g
            id="g1"
            transform="translate(-1991.6799,-986.34667)">
            <g
                id="group-R5">
                <path
                    id="path20"
                    d="m 16385.7,13558.5 v 37.4 h -1075.2 c -82,0 -156.4,33.5 -210.6,87.6 -54.1,54.2 -87.6,128.6 -87.6,210.6 v 2758.2 h 1671.6 v -2758.2 c 0,-82 -33.5,-156.4 -87.6,-210.6 -54.3,-54.1 -128.6,-87.6 -210.6,-87.6 v -37.4 -37.3 c 102.6,0 196,42 263.4,109.5 67.5,67.4 109.5,160.8 109.5,263.4 V 16727 h -1821 v -2832.9 c 0,-102.6 42,-196 109.5,-263.4 67.4,-67.5 160.8,-109.5 263.4,-109.5 h 1075.2 v 37.3"
                    style={{ fill: `${isCharging ? '#FFFFFF' : '#565a5b'}` }} transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
                <path
                    id="path21"
                    d="m 15451.7,16667.4 v 335.2 h 804 v -335.2 h 74.7 v 410 h -953.5 v -410 h 74.8"
                    style={{ fill: `${isCharging ? '#FFFFFF' : '#565a5b'}` }} transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
                <path
                    id="path31"
                    d="m 15247.4,16030.3 h 1197.4 v 20 h -1197.4 z"
                    style={{ fill: `${capacity === 100 && isPlug ? '#5AC134' : '#565a5b'}` }} transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
                <path
                    id="path32"
                    d="m 15245.8,15404.2 h 1197.4 v 20 h -1197.4 z"
                    style={{ fill: `${capacity >= 75 && isPlug ? '#5AC134' : '#565a5b'}` }} transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
                <path
                    id="path33"
                    d="m 15244.1,14778 h 1197.4 v 20 h -1197.4 z"
                    style={{ fill: `${capacity >= 50 && isPlug ? '#5AC134' : '#565a5b'}` }} transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
                <path
                    id="path34"
                    d="m 15242.5,14151.9 h 1197.4 v 20 h -1197.4 z"
                    style={{ fill: `${capacity >= 25 && isPlug ? '#5AC134' : '#565a5b'}` }} transform="matrix(0.13333333,0,0,-0.13333333,0,3263.3333)" />
            </g>
        </g>
    </svg>

    );
};
