import React from "react";
import {Footer} from "antd/es/layout/layout";
import logo from "@assets/logo-blau-weiss.webp";
import audiHeadLogo from "@assets/audi-1-logo-black-and-white.png";

const FooterLayout = () => {
  return (
      <Footer style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#001529', padding: '0 50px'}}>
          <img style={{width:'100px'}} src={audiHeadLogo} alt="Logo"/>
          <img style={{width:'200px'}} src={logo} alt="Logo"/>
      </Footer>
  )
}
export default FooterLayout;