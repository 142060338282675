import React, { useState } from 'react'
import { Header } from 'antd/es/layout/layout'
import audiHeadLogo from '@assets/audi-1-logo-black-and-white.png'
import logo from '@assets/logo-blau-weiss.webp'
import { Button, Form, Input, Menu, Popover } from 'antd'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import routes from '@constants/navigation'
import { Link, useLocation } from 'react-router-dom'
import { logOut, updatePassword } from '@services/api'
import styles from './style.module.scss'

const HeaderNav = ({ roles = [] }) => {
	const location = useLocation()
	const [open, setOpen] = useState(false)

	const handlePasswordChange = async (data) => {
		const { password, password_check } = data
		if (password === password_check) {
			await updatePassword(password)
			await logOut()
			window.location.href = '/'
		}
	}

	const logoutHandler = async () => {
		try {
			await logOut()
			window.location.href = '/'
			localStorage.clear()
		} catch (error) {
			console.log(error)
		}
	}

	const filteredRoutes = routes.filter((route) => roles.includes(route.role))
	const items = filteredRoutes.map((item) => ({ key: item.key, label: <Link to={item.path}>{item.title}</Link> }))

	return (
		<Header
			className={styles.header}
			style={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'space-around',
				justifyItems: 'center',
				position: 'sticky',
				top: 0,
				width: '100%',
				zIndex: '10',
			}}
		>
			<div className={styles.imageContainer}>
				<img className={styles.audiLogo} src={audiHeadLogo} alt="Logo" />
				<img className={styles.logo} src={logo} alt="Logo" />
			</div>
			<div className={styles.menuContainer}>
				<Menu
					defaultSelectedKeys={[location.pathname]}
					items={items}
					mode="horizontal"
					selectedKeys={[location.pathname]}
					theme="dark"
				/>
			</div>
			<div className={styles.buttonContainer}>
				<Popover
					content={
						<Form
							name="audi"
							initialValues={{ remember: true }}
							onFinish={handlePasswordChange}
							onFinishFailed={console.log}
							autoComplete="off"
							className={styles.form}
						>
							<div className={styles.inputContainer}>
								<Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
									<Input.Password />
								</Form.Item>
								<Form.Item
									name="password_check"
									rules={[
										{
											required: true,
											message: 'Please input your password again!',
										},
									]}
								>
									<Input.Password />
								</Form.Item>
							</div>
							<Button type="primary" htmlType="submit" className={styles.button}>
								Change
							</Button>
						</Form>
					}
					title="Audi Pooling System Credentials"
					trigger="click"
					open={open}
					onOpenChange={setOpen}
				>
					<Button icon={<UserOutlined />} type="primary" />
				</Popover>
				<Button icon={<LogoutOutlined />} onClick={logoutHandler} type="primary" className={styles.desktopButton}>
					Log Out
				</Button>
				<Button icon={<LogoutOutlined />} onClick={logoutHandler} type="primary" className={styles.mobileButton} />
			</div>
		</Header>
	)
}

export default HeaderNav
