import React from "react";


export const TargetDivider = ({ style, className }) => {
    return <svg
        version="1.1"
        id="svg1"
        width="415px"
        height="6px"
        viewBox="0 0 415 6"
        style={style}
        className={className}
    >
        <path
            d="M4374.04 16065.3h60v50h-60zM4553.91 16065.3h119.871v50H4553.91Zm239.73 0h119.871v50H4793.64Zm239.74 0h119.859v50H5033.38Zm239.73 0h119.871v50H5273.11Zm239.73 0h119.871v50H5512.84Zm239.74 0h119.859v50H5752.58Zm239.73 0h119.871v50H5992.31Zm239.74 0h119.859v50H6232.05Zm239.73 0h119.867v50H6471.78Zm239.73 0h119.867v50H6711.51Zm239.74 0h119.859v50H6951.25Zm239.73 0h119.871v50H7190.98ZM7430.72 16065.3h60v50h-60z"
            style={{
                fill: "#5ac134",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
                width: '415px'
            }}
            transform="matrix(.13333 0 0 -.13333 -583.205 2148.707)" />
    </svg>;
};
