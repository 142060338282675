import React from "react";

export const ClockIcon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width="108px"
        height="108px"
        viewBox="0 0 28.56 28.561"
        style={{ marginTop: '30px' }}
        className={className}
    >
        <path
            d="M9236.6 17797.2H9219c-.04-205.1-164.53-369.6-369.59-369.6-205.06 0-369.55 164.5-369.59 369.6.04 205.1 164.53 369.5 369.59 369.6 205.06-.1 369.55-164.5 369.59-369.6h35.2c.01 112.1-45.09 213.4-118.23 286.6-73.11 73.1-174.45 118.2-286.56 118.2-112.12 0-213.45-45.1-286.56-118.2-73.14-73.2-118.24-174.5-118.23-286.6-.01-112.1 45.09-213.5 118.23-286.6 73.11-73.1 174.44-118.2 286.56-118.2 112.11 0 213.45 45.1 286.56 118.2 73.13 73.1 118.24 174.5 118.23 286.6h-17.6"
            style={{
                fill: "#fff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.03528 0 0 -.03528 -297.907 642.126)" />
        <path
            d="m8849.45 17814.9-208.94.1-.02-35.2h208.95"
            style={{
                fill: "#fff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.03528 0 0 -.03528 -297.907 642.126)" />
        <path
            d="m8865.16 17789.3 145.29 288.3-31.43 15.8-145.29-288.3"
            style={{
                fill: "#fff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.03528 0 0 -.03528 -297.907 642.126)" />
        <path
            d="M8867.29 17797.4c0 9.8-7.95 17.7-17.76 17.7-9.81 0-17.76-7.9-17.76-17.7 0-9.8 7.95-17.8 17.76-17.8 9.81 0 17.76 8 17.76 17.8"
            style={{
                fill: "#040606",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.03528 0 0 -.03528 -297.907 642.126)" />
        <path
            d="M8867.29 17797.4c0 9.8-7.95 17.7-17.76 17.7-9.81 0-17.76-7.9-17.76-17.7 0-9.8 7.95-17.8 17.76-17.8 9.81 0 17.76 8 17.76 17.8 0-9.8-7.95-17.8-17.76-17.8-9.81 0-17.76 8-17.76 17.8s7.95 17.7 17.76 17.7c9.81 0 17.76-7.9 17.76-17.7"
            style={{
                fill: "#fff",
                fillOpacity: 1,
                fillRule: "nonzero",
                stroke: "none",
            }}
            transform="matrix(.03528 0 0 -.03528 -297.907 642.126)" />
    </svg>
);
