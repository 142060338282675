import React, { useState } from 'react'
import { Input, Button, message, Modal } from 'antd'
import styles from './style.module.scss'
import { deleteUserbyId } from '@services/api'

function AdminTab() {
	const [userId, setUserId] = useState('')
	const [messageApi, contextHolder] = message.useMessage()

	const info = (msg) => {
		messageApi.info(msg)
	}

	const handleDeleteUser = async () => {
		try {
			const res = await deleteUserbyId(userId)
			if (res.status === 204) {
				info('User Deleted Successfully')
				setUserId('')
			} else {
				info('User Not Found')
			}
		} catch (err) {
			info('User Not Found')
		}
	}

	const showDeleteConfirm = () => {
		Modal.confirm({
			title: 'Are you sure you want to delete this user?',
			content: `User ID: ${userId}`,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			onOk() {
				handleDeleteUser()
			},
			onCancel() {
				setUserId('')
			},
		})
	}

	return (
		<div className={styles.adminContainer}>
			{contextHolder}
			<h2>Admin Panel</h2>
			<div>
				<Input
					placeholder="Enter User ID"
					value={userId}
					onChange={(e) => setUserId(e.target.value)}
					style={{ width: 200, marginRight: 10 }}
				/>
				<Button type="primary" onClick={showDeleteConfirm} disabled={!userId.length}>
					Delete User
				</Button>
			</div>
		</div>
	)
}

export default AdminTab
