import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Layout } from 'antd'
import { ToastContainer } from 'react-toastify'
import { jwtDecode } from 'jwt-decode'
import 'react-toastify/dist/ReactToastify.css'

import HeaderNav from '@components/Header'
import FooterLayout from '@components/Footer'
import routes from '@constants/navigation'
import keycloak from 'keycloak'
import { ADMIN_ROLE, POOL_OPERATOR_ROLE, USER_ROLE } from '@constants/common'

import 'App.css'

const { Content } = Layout
export const AppContext = React.createContext({
	hint: {},
	setHint: () => {},
})

const navigateTo = (roles) => {
	if (roles.includes(ADMIN_ROLE)) {
		return '/admin'
	}

	if (roles.includes(POOL_OPERATOR_ROLE)) {
		return '/'
	}

	return '/account'
}

const initialRoles = [USER_ROLE]

const App = () => {
	const [hint, setHint] = useState()
	const [roles, setRoles] = useState(initialRoles)
	const [isUser, setIsUser] = useState(false)

	useEffect(() => {
		if (keycloak?.token) {
			const decodedToken = jwtDecode(keycloak.token)
			const roles = decodedToken?.resource_access?.ie2s_backend?.roles || initialRoles

			setRoles(roles)
		}
	}, [keycloak?.token])

	useEffect(() => {
		setIsUser(roles.length === 1 && roles.includes(USER_ROLE))
	}, [roles])

	return (
		<BrowserRouter>
			<Layout style={{ minHeight: '100vh' }}>
				<ToastContainer />
				{!isUser && <HeaderNav roles={roles} />}
				<Content>
					<AppContext.Provider value={{ hint, setHint }}>
						<Routes>
							{routes
								.filter(({ role }) => roles.includes(role))
								.map((item, index) => (
									<Route path={item.path} element={item.component} key={index} />
								))}
							<Route path="*" element={<Navigate to={navigateTo(roles)} />} />
						</Routes>
					</AppContext.Provider>
				</Content>
				{!isUser && <FooterLayout />}
			</Layout>
		</BrowserRouter>
	)
}
export default App
