import AccountTab from '@layouts/AccountTab'
import AdminTab from '@layouts/AdminTab'
import BevBoardTab from '@layouts/bevBoardTab'
import VirtualFormTab from '@layouts/VirtualFormTab'
import { ADMIN_ROLE, POOL_OPERATOR_ROLE, USER_ROLE } from './common'

const routes = [
	{
		key: '/',
		path: '/',
		exact: true,
		title: 'BEV Control Board',
		role: POOL_OPERATOR_ROLE,
		component: <BevBoardTab />,
	},
	{
		key: '/virtual_bev_inputs',
		path: '/virtual_bev_inputs',
		title: 'Virtual BEV inputs',
		role: POOL_OPERATOR_ROLE,
		component: <VirtualFormTab />,
	},
	{
		key: '/admin',
		path: '/admin',
		title: 'Admin Panel',
		role: ADMIN_ROLE,
		component: <AdminTab />,
	},
	{
		key: '/account',
		path: '/account',
		title: 'Account',
		role: USER_ROLE,
		component: <AccountTab />,
	},
]

export default routes
