export const downloadExcelFile = (data) => {
    // Create a Blob object from the file data
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a URL object from the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to download the file
    const link = document.createElement('a');
    link.href = url;
    link.download = 'example.xlsx';

    // Append the link to the DOM and click it to download the file
    document.body.appendChild(link);
    link.click();

    // Cleanup the URL object
    URL.revokeObjectURL(url);
}


export const formatDate = (date) => {
    if (date) {
        const time = date;
        const res = new Intl.DateTimeFormat("en", { hour: 'numeric', minute: 'numeric', hourCycle: 'h24' }).format(new Date(time));
        return res;
    }
    return 'N/A'
};
export const convertToNatural = (num) => {
    if (num == null || isNaN(num) || typeof num === 'undefined') {
        return 'N/A';
    }

    const multipliedNumber = num * 100;
    const roundedNumber = Math.round(multipliedNumber);

    return roundedNumber;
}