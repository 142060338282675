// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_virtualForm__KfqZY {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.style_virtualForm__KfqZY .style_profMain__WbFok {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .style_virtualForm__KfqZY .style_profMain__WbFok {
    flex-direction: column;
  }
}
.style_virtualForm__KfqZY .style_profMain__WbFok .style_profLeft__xB8ts {
  display: flex;
  flex-direction: column;
}
.style_virtualForm__KfqZY .style_profMain__WbFok .style_profLeft__xB8ts .style_profCol__iJ89L {
  display: flex;
  flex-direction: column;
}
.style_virtualForm__KfqZY .style_profMain__WbFok .style_profLeft__xB8ts .style_profProp__HMEMm {
  align-items: center;
  display: flex;
}
.style_virtualForm__KfqZY .style_buttonContainer__h1u2P {
  align-items: center;
  display: flex;
  justify-content: center;
}
.style_virtualForm__KfqZY .style_sliderStyle__2wiJQ {
  width: 300px;
}
@media screen and (max-width: 768px) {
  .style_virtualForm__KfqZY .style_sliderStyle__2wiJQ {
    width: 150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/VirtualFormTab/style.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,aAAA;AACF;AACE;EACE,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,WAAA;AACJ;AACI;EANF;IAOI,sBAAA;EAEJ;AACF;AAAI;EACE,aAAA;EACA,sBAAA;AAEN;AAAM;EACE,aAAA;EACA,sBAAA;AAER;AACM;EACE,mBAAA;EACA,aAAA;AACR;AAIE;EACE,mBAAA;EACA,aAAA;EACA,uBAAA;AAFJ;AAKE;EACE,YAAA;AAHJ;AAKI;EAHF;IAII,YAAA;EAFJ;AACF","sourcesContent":[".virtualForm {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 20px;\n\n  .profMain {\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n\n    @media screen and (max-width: 768px) {\n      flex-direction: column;\n    }\n\n    .profLeft {\n      display: flex;\n      flex-direction: column;\n\n      .profCol {\n        display: flex;\n        flex-direction: column;\n      }\n\n      .profProp {\n        align-items: center;\n        display: flex;\n      }\n    }\n  }\n\n  .buttonContainer {\n    align-items: center;\n    display: flex;\n    justify-content: center;\n  }\n\n  .sliderStyle {\n    width: 300px;\n\n    @media screen and (max-width: 768px) {\n      width: 150px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"virtualForm": `style_virtualForm__KfqZY`,
	"profMain": `style_profMain__WbFok`,
	"profLeft": `style_profLeft__xB8ts`,
	"profCol": `style_profCol__iJ89L`,
	"profProp": `style_profProp__HMEMm`,
	"buttonContainer": `style_buttonContainer__h1u2P`,
	"sliderStyle": `style_sliderStyle__2wiJQ`
};
export default ___CSS_LOADER_EXPORT___;
