import React from 'react'
import ExtendedStatistic from './extend/extendedStatistic'

const BevBoardTab = () => {
	return (
		<div className="main_wrapper">
			<ExtendedStatistic />
		</div>
	)
}
export default BevBoardTab
