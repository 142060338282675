import React from "react";
import { Divider } from "antd";
import styles from "./style.module.scss";
import { convertToNatural } from "@services/utils";

export const OptimizationCharger = ({ daily = {} }) => {
    const { avgPriceForChargingOnChargingShifting, avgPriceForChargingNormal } = daily;
    return <div className={styles.optimizationCharger}>
        <div className={styles.leftOptimization}>
            <h3>{convertToNatural(avgPriceForChargingOnChargingShifting)} <span>ct/<br />kwh</span></h3>
            <p>thanks to <br /> optimization</p>
        </div>
        <Divider type="vertical" className={styles.dividerVertical} />
        <div className={styles.rightOptimization}>
            <h3>{convertToNatural(avgPriceForChargingNormal)} <span>ct/<br />kwh</span></h3>
            <p>without <br /> optimization</p>
        </div>
    </div>;
};
