import React from "react";
import styles from "./style.module.scss";
import { formatDate } from "@services/utils";
import { ArrowInDivider } from "./ArrowInDivider";
import { TargetDivider } from "./TargetDivider";

export const DividerComponent = ({
  actual,
  isCharging,
  target,
  chargingEst,
  isPlug,
}) => {
  const percentageHeight = {
    height: `${actual}% `,
  };

  const iconPosition = {
    top: `calc(${100 - actual}% - 40px)`,
  };

  const targetPosition = {
    top: `calc(${100 - target}%)`,
  };

  const chargingTime = {
    top: `calc(${100 - target}% - 35px)`,
  };

  return (
    <div className={styles.dividersCapacity}>
      {isCharging && (
        <>
          <div style={percentageHeight} className={styles.filledBlock}></div>
          <ArrowInDivider style={iconPosition} className={styles.arrowIcon} />
          <TargetDivider
            style={targetPosition}
            className={styles.targetDivider}
          />
        </>
      )}
      {isPlug && (
        <b style={chargingTime} className={styles.chargingETA}>
          {formatDate(chargingEst)}
        </b>
      )}

      {Array.from({ length: 6 }).map((_, index) => (
        <div
          className={[
            styles.singleDivider,
            isCharging ? styles.whiteDivider : "",
          ].join(" ")}
          key={index}
        ></div>
      ))}
    </div>
  );
};
